import styled from "styled-components"
import theme from "../constants/theme"

export const StyledLayout = styled.div`
  height: 100%;
  background-color: ${theme.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: center;
`
