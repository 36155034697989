import styled from "styled-components"
import theme from "../constants/theme"

export const StyledSocial = styled.a`
  margin: 1em;
  text-decoration: none;
`

export const StyledSocialIcon = styled.div`
  text-align: center;
  color: ${theme.primary};
  font-size: 2em;
`

export const StyledSocialText = styled.p`
  color: white;

  font-size: 1.5em;
  font-weight: bold;
  transition: color ease-out 1s;

  &:hover {
    color: ${theme.primary};
  }
`
