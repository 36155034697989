import React from "react"
import { FaInstagram, FaTwitch, FaTwitter, FaYoutube } from "react-icons/fa"
import { StyledFooter } from "./components/Footer"
import { StyledLayout } from "./components/Layout"
import Logo, { StyledLogoContainer } from "./components/Logo"
import {
  StyledSocial,
  StyledSocialIcon,
  StyledSocialText,
} from "./components/Social"

function App() {
  return (
    <StyledLayout>
      <StyledLogoContainer>
        <Logo />
      </StyledLogoContainer>

      <StyledFooter>
        {/* Instagram */}
        <StyledSocial
          href="https://instagram.com/frontendrules"
          target="_blank"
        >
          <StyledSocialIcon>
            <FaInstagram />
          </StyledSocialIcon>
          <StyledSocialText>@frontendrules</StyledSocialText>
        </StyledSocial>

        {/* Twitter */}
        <StyledSocial href="https://twitter.com/frontendrules" target="_blank">
          <StyledSocialIcon>
            <FaTwitter />
          </StyledSocialIcon>
          <StyledSocialText>@frontendrules</StyledSocialText>
        </StyledSocial>

        {/* Twitch */}
        <StyledSocial href="https://twitch.com/frontendrules" target="_blank">
          <StyledSocialIcon>
            <FaTwitch />
          </StyledSocialIcon>
          <StyledSocialText>@frontendrules</StyledSocialText>
        </StyledSocial>

        {/* Youtube */}
        <StyledSocial
          href="https://www.youtube.com/channel/UC7YEHZP7QJzRV6G7OEILQkw"
          target="_blank"
        >
          <StyledSocialIcon>
            <FaYoutube />
          </StyledSocialIcon>
          <StyledSocialText>@frontendrules</StyledSocialText>
        </StyledSocial>
      </StyledFooter>
    </StyledLayout>
  )
}

export default App
